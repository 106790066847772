import {
  TRACKS_ADD_CITIES,
  TRACKS_ADD_COLLECTION,
  TRACKS_ADD_COUNTRIES,
  TRACKS_ADD_GENRES,
  TRACKS_ADD_TOP_CHART,
  TRACKS_SEARCH_ERROR,
  TRACKS_SEARCH_SEARCHING,
  TRACKS_SET_SORT_TYPE,
} from '@/store/tracks/tracks.enums';

const types = {
  [TRACKS_SEARCH_SEARCHING](state) {
    state.error = null;
    state.searching = true;
  },
  [TRACKS_SEARCH_ERROR](state, error) {
    state.error = error;
    state.searching = false;
  },
  [TRACKS_ADD_COLLECTION](state, tracks) {
    state.error = null;
    state.searching = false;
    state.tracks = tracks;
  },
  [TRACKS_SET_SORT_TYPE](state, sortType) {
    state.sortBy = sortType;
  },
  [TRACKS_ADD_TOP_CHART](state, tracks) {
    state.topChartTracks = tracks;
  },
  [TRACKS_ADD_GENRES](state, genres) {
    state.trackGenres = genres;
  },
  [TRACKS_ADD_CITIES](state, cities) {
    state.trackCities = cities;
  },
  [TRACKS_ADD_COUNTRIES](state, countries) {
    state.trackCountries = countries;
  },
};

export default types;
