<template>
  <span
    :class="{'stat--active': filteredBy}"
    class="stat"
  >
    <Icon :type="`ios-${icon}`" />
    {{ statValue | shorten }}
  </span>
</template>

<script>

  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  export default {
    name: 'TrackStat',
    filters: {
      formatStat(val) {
        return (val || 0)
          .toString()
          .replace(/\d(?=(\d{3})+$)/g, '$&,');
      },
      shorten(number) {
        // what tier? (determines SI symbol)
        // eslint-disable-next-line no-bitwise
        const tier = Math.log10(number) / 3 | 0;

        // if zero, we don't need a suffix
        if (tier === 0) return number;

        // get suffix and determine scale
        const suffix = SI_SYMBOL[tier];
        const scale = 10 ** (tier * 3);

        // scale the number
        const scaled = number / scale;

        // format number and add suffix
        return scaled.toFixed(1) + suffix;
      },
    },
    props: {
      filteredBy: {
        type: Boolean,
        required: false,
        default: false,
      },
      statValue: {
        type: [String, Number],
        required: false,
        default: 0,
      },
      icon: {
        type: String,
        required: true,
      },
    },
  };

</script>

<style lang="scss">

  .stat {
    flex-basis: 20%;
    text-align: center;

    @media (max-width: 425px) {
      i {
        display: block;
      }
      text-align: center !important;
    }

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }

    &--active {
      color: #57a3f3;
    }
  }

</style>
