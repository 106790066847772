<template>
  <div
    class="player"
  >
    <Row
      v-if="nowPlaying"
      :gutter="10"
      type="flex"
      align="middle"
    >
      <Col>
        <span
          ref="playPauseToggler"
          class="player__play"
          @click="toggle"
        >
          <Icon
            v-if="!isPlaying"
            type="ios-play"
            size="18"
          />
          <Icon
            v-if="isPlaying"
            type="ios-pause"
            size="18"
          />
        </span>
      </Col>

      <Col>
        <span
          :title="nowPlaying.title"
          class="player__track-name"
        >
          {{ nowPlaying.title }}
        </span>
      </Col>

      <Col>
        <span class="player__duration">
          {{ timeElapsed | msToTime }}
        </span>
      </Col>

      <Col :class="'player__track-wrapper'">
        <div
          ref="playerTrack"
          class="player__track-wrap"
          @click="seek"
        >
          <div
            class="player__track"
          >
            <span
              :style="{transform: `translate3d(${seekBarPosition}px, -50%, 0)`}"
              class="player__track__thumb"
              @mousedown="startSeek"
              @touchstart="startSeek"
            />
          </div>
        </div>
      </Col>

      <Col>
        <span class="player__duration">
          {{ nowPlaying.duration | msToTime }}
        </span>
      </Col>

      <Col>
        <a
          :href="nowPlaying.permalink_url"
          target="_blank"
        >
          <Icon
            type="ios-link"
            size="18"
          />
        </a>
      </Col>

      <Col v-if="nowPlaying.isDonwloadable">
        <a
          :href="nowPlaying.downloadLink"
          target="_blank"
        >
          <Icon
            type="ios-cart"
            size="18"
          />
        </a>
      </Col>

      <Col>
        <span
          class="player__close"
          @click="closePlayer"
        >
          <Icon
            type="ios-close-circle"
            size="18"
          />
        </span>
      </Col>
    </Row>

    <iframe
      ref="iframePlayer"
      src="https://w.soundcloud.com/player/?url="
      class="player__iframe"
      width="100%"
      height="166"
      scrolling="no"
      frameborder="no"
      allow="autoplay"
      @load="iframeLoaded"
    />
  </div>
</template>

<script>

  import { PLAYER_REMOVE_PLAYING_TRACK } from '@/store/player/player.enums';
  import throttle from 'lodash/throttle';
  import { mapState, mapMutations } from 'vuex';

  const handledKeys = ['Escape', ' ', 'Spacebar'];
  const inputElements = [
      'INPUT',
      'SELECT',
  ];

  export default {
    name: 'Player',
    filters: {
      msToTime(ms = 0) {
        let time = new Date(ms)
          .toISOString()
          .slice(11, -5);

        if (time.charAt(0) === '0' && time.charAt(1) === '0') time = time.slice(3);

        return time;
      },
    },
    data() {
      return {
        timeElapsed: 0,
        seekBarPosition: -9,
        isPlaying: false,
        frameIsLoaded: false,
        isSeeking: false,
      };
    },
    computed: {
      ...mapState('Player', { nowPlaying: 'track' }),
    },
    watch: {
      nowPlaying() {
        this.timeElapsed = 0;
        this.seekBarPosition = -9;
        this.frameIsLoaded = false;
        if (this.nowPlaying && this.nowPlaying.uri) {
          this.scPlayer.load(this.nowPlaying.uri.toString(), {
            auto_play: true,
            show_artwork: false,
          });
        } else {
          this.pause();
        }
      },
    },
    mounted() {
      // eslint-disable-next-line no-undef
      this.scPlayer = SC.Widget(this.$refs.iframePlayer);
      // eslint-disable-next-line no-undef
      this.scPlayer.bind(SC.Widget.Events.PLAY_PROGRESS, this.getPosition);
      // eslint-disable-next-line no-undef
      this.scPlayer.bind(SC.Widget.Events.FINISH, this.pause);
      // eslint-disable-next-line no-undef
      this.scPlayer.bind(SC.Widget.Events.PAUSE, this.pause);
      // eslint-disable-next-line no-undef
      this.scPlayer.bind(SC.Widget.Events.PLAY, this.play);

      document.addEventListener('keydown', this.keyboardHandler);
    },
    created() {
      const isPlaying = this.$watch('nowPlaying', () => {
        document.addEventListener('mousemove', this.seekDrag);
        document.addEventListener('touchmove', this.seekDrag);
        document.addEventListener('mouseup', this.stopSeek);
        document.addEventListener('touchend', this.stopSeek);
        isPlaying();
      });

      this.keyboardHandler = (event) => {
        const { nodeName } = document.activeElement;
        if (handledKeys.includes(event.key) && !inputElements.includes(nodeName)) {
          event.preventDefault();

          switch (event.key) {
            case handledKeys[0]:
              this.closePlayer();
              break;
            case handledKeys[1]:
            case handledKeys[2]:
              this.toggle();
              break;
            default:
              return null;
          }
        }

        return null;
      };
    },
    destroyed() {
      document.removeEventListener('mousemove', this.seekDrag);
      document.removeEventListener('touchmove', this.seekDrag);
      document.removeEventListener('mouseup', this.stopSeek);
      document.removeEventListener('touchend', this.stopSeek);
      document.removeEventListener('keydown', this.keyboardHandler);

      this.scPlayer = null;
    },
    methods: {
      ...mapMutations('Player', {
        removePlayingTrack: PLAYER_REMOVE_PLAYING_TRACK,
      }),
      startSeek() {
        this.isSeeking = true;
      },
      seek(e) {
        const xPosition = e.pageX;
        const position = xPosition - this.$refs.playerTrack.getBoundingClientRect().left - 9;
        const percentage = 100 * (position / this.$refs.playerTrack.offsetWidth);
        this.scPlayer.seekTo((this.nowPlaying.duration * percentage) / 100);
      },
      stopSeek(e) {
        if (this.isSeeking) {
          this.isSeeking = false;
          this.seek(e);
        }
      },
      seekDrag(e) {
        if (this.isSeeking) this.seek(e);
      },
      iframeLoaded() {
        this.frameIsLoaded = true;
      },
      // eslint-disable-next-line func-names
      getPosition: throttle(function ({ currentPosition, relativePosition }) {
        if (this.frameIsLoaded) {
          this.timeElapsed = currentPosition;
          this.seekBarPosition = ((this.$refs.playerTrack.offsetWidth / 100) * (relativePosition * 100)) - 9;
        }
      }, 100),
      closePlayer() {
        this.timeElapsed = 0;
        this.seekBarPosition = -9;
        this.isPlaying = false;
        this.frameIsLoaded = false;
        this.isSeeking = false;
        this.removePlayingTrack();
      },
      play() {
        this.scPlayer.isPaused((paused) => paused && this.scPlayer.play());
        this.isPlaying = true;
      },
      pause() {
        this.scPlayer.isPaused((paused) => !paused && this.scPlayer.pause());
        this.isPlaying = false;
      },
      toggle() {
        if (this.frameIsLoaded) {
          if (this.isPlaying) {
            this.pause();
          } else {
            this.play();
          }
        }
      },
    },
  };

</script>

<style lang="scss">

  .player {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 1px);
    height: 41px;
    width: 100%;
    max-width: 1024px;
    overflow: hidden;
    border-radius: 4px 4px 0px 0px;

    @media (max-width: 425px) {
      padding-bottom: env(safe-area-inset-bottom);
    }

    a, &__close {
      color: white;
      text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      &:hover {
        text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
      }
    }

    &__close {
      cursor: pointer;
    }

    > div {
      color: white;
      background-image: linear-gradient(135deg, #f77062 0%, #fe5196 100%);
      height: 100%;
      padding: 0 10px;
      z-index: 99;
      position: relative;
    }

    &__track-name {
      display: block;
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__play {
      display: flex;
      border-radius: 100%;
      cursor: pointer;
      width: 25px;
      height: 25px;
      justify-content: center;
      align-items: center;
      transition: .15s linear;

      i {
        margin-right: -2px;
      }

      &:hover {
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);

        i {
          color: #f77062;
        }
      }
    }

    &__track-wrapper {
      flex-grow: 1;
      margin: 0 10px;
    }

    &__track-wrap {
      padding: 10px 0;
      cursor: pointer;
    }

    &__track {
      position: relative;
      width: 100%;
      height: 4px;
      background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
      border-radius: 2px;

      &__thumb {
        border-radius: 100%;
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate3d(-9px, -50%, 0);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
        transition: .1s linear;
      }

    }

    &__iframe {
      visibility: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
    }

  }

</style>
