export const SORT_BY_REPOSTS = 'SORT_BY_REPOSTS';
export const SORT_BY_DOWNLOADS = 'SORT_BY_DOWNLOADS';
export const SORT_BY_LIKES = 'SORT_BY_LIKES';
export const SORT_BY_PLAYS = 'SORTY_BY_PLAYS';
export const SORT_BY_COMMENTS = 'SORT_BY_COMMENTS';
export const SORT_BY_DATE = 'SORT_BY_DATE';
export const SORT_NATURALLY = 'NATURAL';

export const trackSorters = {
  [SORT_BY_REPOSTS]({ reposts_count: a }, { reposts_count: b }) {
    return b - a;
  },
  [SORT_BY_DOWNLOADS]({ download_count: a }, { download_count: b }) {
    return b - a;
  },
  [SORT_BY_LIKES]({ likes_count: a }, { likes_count: b }) {
    return b - a;
  },
  [SORT_BY_PLAYS]({ playback_count: a }, { playback_count: b }) {
    return b - a;
  },
  [SORT_BY_COMMENTS]({ comment_count: a }, { comment_count: b }) {
    return b - a;
  },
  [SORT_BY_DATE]({ created_at: a }, { created_at: b }) {
    return b - a;
  },
};

export const genreSorter = ([, countA], [, countB]) => countB - countA;

export const stringSort = (a, b) => a.localeCompare(b);

const genreFilter = ({ genre: trackGenre }, genre = []) => (genre.length === 0
  ? true
  : genre.indexOf(trackGenre) > -1);

const downloadFilter = ({ isDownloadable }) => isDownloadable;

const countryFilter = ({ country: trackCountry }, country) => trackCountry === country;
const cityFilter = ({ city: trackCity }, city) => trackCity === city;

export const trackFilter = (track, filter) => {
  const filterResults = [];
  if (filter.genreFilter.length > 0) filterResults.push(genreFilter(track, filter.genreFilter));
  if (filter.downloadable) filterResults.push(downloadFilter(track));
  if (filter.country) filterResults.push(countryFilter(track, filter.country));
  if (filter.city) filterResults.push(cityFilter(track, filter.city));

  return filterResults.indexOf(false) === -1;
};
