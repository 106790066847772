/* eslint-disable camelcase */
import md5 from 'md5';

const processTopChartCollection = (collection) => collection.reduce((acc, { track }) => {
  const {
    genre: rawGengre,
    artwork_url,
    comment_count = 0,
    created_at,
    download_count = 0,
    likes_count = 0,
    playback_count = 0,
    reposts_count = 0,
    uri,
    title,
    duration,
    permalink,
    permalink_url,
    purchase_url,
  } = track;

  acc[md5(permalink || title)] = {
    genre: (rawGengre || 'empty').toLowerCase(),
    id: md5(permalink || title),
    // eslint-disable-next-line global-require
    artwork_url: artwork_url || require('../../public/artwork.gradient.svg'),
    comment_count,
    created_at: new Date(created_at),
    download_count,
    likes_count,
    playback_count,
    reposts_count,
    duration,
    uri,
    permalink_url,
    purchase_url,
    title: title || permalink,
  };
  return acc;
}, {});

export default processTopChartCollection;
