import Vue from 'vue';
import Vuex from 'vuex';

import Tracks from './store/tracks';
import Player from './store/player';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Tracks,
    Player,
  },
  state: {},
});
