<template>
  <div class="search">
    <Card style="margin-bottom: 20px">
      <Input
          v-model="searchValue"
          placeholder="Search for something"
      />

      <Row
          class-name="searchers"
          :gutter="10"
          style="margin-top: 10px"
      >
        <Col
            :xs="24"
            :md="6"
        >
          <Select
              v-model="created_at"
              placeholder="Released in?"
          >
            <Option :value="''">
              Any time
            </Option>
            <Option :value="'last_year'">
              Past year
            </Option>
            <Option :value="'last_month'">
              Past month
            </Option>
            <Option :value="'last_week'">
              Past week
            </Option>
            <Option :value="'last_day'">
              Past day
            </Option>
            <Option :value="'last_hour'">
              Past hour
            </Option>
          </Select>
        </Col>

        <Col
            :md="6"
            :xs="24"
        >
          <Select
              v-model="duration"
              placeholder="How long it should be?"
          >
            <Option :value="''">
              Any length
            </Option>
            <Option :value="'short'">
              less than 2 min
            </Option>
            <Option :value="'medium'">
              more than 2 less than 10 min
            </Option>
            <Option :value="'long'">
              more than 10 less than 30 min
            </Option>
            <Option :value="'epic'">
              more than 30 min
            </Option>
          </Select>
        </Col>

        <Col
            :md="6"
            :xs="24"
        >
          <Select
              v-model="genre_or_tag"
              placeholder="Part of which genre?"
              not-found-text="No genre found"
              filterable
          >
            <Option :value="''">
              All
            </Option>
            <Option
                v-for="genre in genres"
                :key="genre"
                :value="genre.toLowerCase()"
            >
              {{ genre }}
            </Option>
          </Select>
        </Col>
      </Row>
    </Card>

    <Card
        v-if="!searching && startedSearch && tracks.length > 0"
        style="margin-bottom: 20px"
    >
      <Row
          :gutter="10"
          type="flex"
          align="middle"
      >
        <Col>
          <span>⚡ filters and sorters:</span>
        </Col>

        <Col
            :md="4"
            :xs="24"
        >
          <Checkbox v-model="downloadable">
            Downloadable Only
          </Checkbox>
        </Col>

        <Col
            :md="3"
            :xs="24"
        >
          <Select
              v-model="trackSortType"
              placeholder="Sort By"
          >
            <Option :value="NO_SORT">
              Natural
            </Option>
            <Option :value="SORT_BY_LIKES">
              Likes
            </Option>
            <Option :value="SORT_BY_REPOSTS">
              Reposts
            </Option>
            <Option :value="SORT_BY_PLAYS">
              Plays
            </Option>
            <Option :value="SORT_BY_COMMENTS">
              Comments
            </Option>
            <Option :value="SORT_BY_DATE">
              Date uploaded
            </Option>
          </Select>
        </Col>

        <Col
            :md="8"
            :xs="24"
        >
          <Select
              v-model="trackGenre"
              placeholder="Select genre"
              not-found-text="No genre found"
              filterable
              multiple
          >
            <Option
                v-for="genre in trackGenres"
                :key="genre.name"
                :value="genre.name"
            >
              {{ genre.name || 'No Genre' }} ({{ genre.count }})
            </Option>
          </Select>
        </Col>
      </Row>
      <Row :gutter="10" style="margin-top: 10px">
        <Col
            :md="8"
            :xs="24"
        >
          <Select
              v-model="country"
              placeholder="Select Country"
              not-found-text="No country found"
              filterable
          >
            <Option :value="''">
              No Country
            </Option>
            <Option
                v-for="country in trackCountries"
                :key="country"
                :value="country"
            >
              {{ country }}
            </Option>
          </Select>
        </Col>
        <Col
            :md="8"
            :xs="24"
        >
          <Select
              v-model="city"
              placeholder="Select city"
              not-found-text="No city found"
              filterable
          >
            <Option :value="''">
              No City
            </Option>
            <Option
                v-for="city in trackCities"
                :key="city"
                :value="city"
            >
              {{ city }}
            </Option>
          </Select>
        </Col>
      </Row>
    </Card>

    <div class="tracks">
      <template v-if="!searching && tracks.length > 0">
        <transition-group name="track-list">
          <Track
              v-for="track in tracks"
              :key="track.id"
              :track="track"
          />
        </transition-group>

        <Row
            v-if="showNewTracks"
            type="flex"
            justify="center"
        >
          <h3
              ref="showMoreTracks"
              class="tracks__show-more"
              @click="observerCallback"
          >
            Show more tracks
          </h3>
        </Row>
      </template>

      <template v-if="searching">
        <Row
            type="flex"
            justify="center"
        >
          <semipolar-spinner
              :animation-duration="2000"
              :size="65"
              color="#ff1d5e"
          />
        </Row>
      </template>

      <template v-if="!searching && startedSearch && tracks.length === 0">
        <Row
            type="flex"
            justify="center"
        >
          <h1>
            Nothing was found, sorry. You should try again
          </h1>
        </Row>
      </template>

      <template v-if="tracks.length === 0 && !searching && !startedSearch">
        <h2 style="margin: 10px 0;">
          Trending & 🔥 music
        </h2>

        <Track
            v-for="track in topChartsTracks(limit)"
            :key="track.id"
            :track="track"
        />

        <Row
            v-if="limit === 5"
            type="flex"
            justify="center"
            style="margin-top: 10px"
        >
          <Button @click="setFullLimit">
            Show more
          </Button>
        </Row>
      </template>
    </div>
  </div>
</template>

<script>
import { SemipolarSpinner } from 'epic-spinners';

import {
  SORT_BY_COMMENTS, SORT_BY_DATE,
  SORT_BY_LIKES,
  SORT_BY_PLAYS,
  SORT_BY_REPOSTS, SORT_NATURALLY,
} from '@/store/tracks/tracks.helpers';
import throttle from 'lodash/debounce';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import {
  TRACKS_GET_CITIES_SORTED, TRACKS_GET_COUNTRIES_SORTED,
  TRACKS_GET_FILTERED_PAGED,
  TRACKS_GET_GENRES_SORTED,
  TRACKS_GET_SORTED_FILTERED_PAGED,
  TRACKS_SEARCH,
  TRACKS_SET_SORT_TYPE,
  TRACKS_TOP_CHART_GET_ALL,
} from '@/store/tracks/tracks.enums';
import Track from '@/components/Track';
import * as genres from '@/enums/genres.enums';

export default {
  name: 'Search',
  components: {
    Track,
    SemipolarSpinner,
  },
  data() {
    return {
      downloadable: false,
      searchValue: '',
      trackGenre: [],
      created_at: null,
      genre_or_tag: null,
      duration: null,
      country: null,
      city: null,
      trackSortType: SORT_BY_LIKES,
      startedSearch: false,
      limit: 5,
      trackLimit: 0,
      showNewTracks: true,
    };
  },
  computed: {
    NO_SORT: () => SORT_NATURALLY,
    SORT_BY_REPOSTS: () => SORT_BY_REPOSTS,
    SORT_BY_LIKES: () => SORT_BY_LIKES,
    SORT_BY_PLAYS: () => SORT_BY_PLAYS,
    SORT_BY_COMMENTS: () => SORT_BY_COMMENTS,
    SORT_BY_DATE: () => SORT_BY_DATE,
    ...mapGetters('Tracks', {
      getTracksUnsorted: TRACKS_GET_FILTERED_PAGED,
      getTracks: TRACKS_GET_SORTED_FILTERED_PAGED,
      topChartsTracks: TRACKS_TOP_CHART_GET_ALL,
      trackGenres: TRACKS_GET_GENRES_SORTED,
      trackCities: TRACKS_GET_CITIES_SORTED,
      trackCountries: TRACKS_GET_COUNTRIES_SORTED,
    }),
    ...mapState('Tracks', ['searching', 'sortBy']),
    tracks() {
      if (this.trackSortType === SORT_NATURALLY) {
        return this.getTracksUnsorted({
          genreFilter: this.trackGenre,
          downloadable: this.downloadable,
          city: this.city,
          country: this.country,
        }, this.trackLimit);
      }

      return this.getTracks({
        genreFilter: this.trackGenre,
        downloadable: this.downloadable,
        city: this.city,
        country: this.country,
      }, this.trackLimit);
    },
    searchFilters() {
      const filters = {};

      if (this.created_at) filters.created_at = this.created_at;
      if (this.genre_or_tag) filters.genre_or_tag = this.genre_or_tag;
      if (this.duration) filters.duration = this.duration;

      return filters;
    },
  },
  watch: {
    trackSortType(newSortType) {
      this.sortTracksBy(newSortType);
    },
    searchValue(term) {
      this.searchThrottled(term);
    },
    searchFilters() {
      this.searchThrottled(this.searchValue);
    },
    tracks(newValue) {
      if (newValue.length > 0) {
        this
            .$nextTick()
            .then(this.initIntersectionObserver);

        if (this.trackLimit > newValue.length) this.showNewTracks = false;
      } else {
        this.cancelIntersectionObserver();
      }
    },
  },
  destroyed() {
    this.cancelIntersectionObserver();
  },
  created() {
    this.observer = new IntersectionObserver(this.observerCallback);
    this.genres = Object.values(genres);
  },
  methods: {
    ...mapActions('Tracks', {
      search: TRACKS_SEARCH,
    }),
    ...mapMutations('Tracks', {
      sortTracksBy: TRACKS_SET_SORT_TYPE,
    }),
    setFullLimit() {
      this.limit = 50;
    },
    initIntersectionObserver() {
      const target = () => this.$refs.showMoreTracks;
      const t = target();
      if (t) {
        this.observer.observe(t);
      }
    },
    observerCallback() {
      this.trackLimit += 20;
    },
    cancelIntersectionObserver() {
      if (this.$refs.showMoreTracks) {
        this.observer.unobserve(this.$refs.showMoreTracks);
      }
      this.observer.disconnect();
    },
    // eslint-disable-next-line func-names
    searchThrottled: throttle(function (term) {
      this.showNewTracks = true;
      this.cancelIntersectionObserver();

      this.startedSearch = true;
      this.trackLimit = 20;
      return this.search({
        searchTerm: term,
        searchFilters: this.searchFilters,
      });
    }, 500),
  },
};

</script>

<style lang="scss">

@media (max-width: 425px) {
  .searchers .ivu-col + .ivu-col {
    margin-top: 10px;
  }
}

.search {
  max-width: 1024px;
  margin: 0 auto;
}

.tracks {
  &__show-more {
    margin: 20px 0;
    text-transform: uppercase;
  }
}

.track-list {
  will-change: transfrom;
  transition: transform 1s;
}

.track-list-move {
  transition: transform 1s;
}

</style>
