import { PLAYER_REMOVE_PLAYING_TRACK, PLAYER_SET_PLAYING_TRACK } from '@/store/player/player.enums';

export default {
  [PLAYER_SET_PLAYING_TRACK](state, track) {
    state.track = track;
    state.trackHistory.push(track.id);
  },
  [PLAYER_REMOVE_PLAYING_TRACK](state) {
    state.track = undefined;
  },
};
