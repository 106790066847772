import getters from './player.getters';
import actions from './player.actions';
import mutations from './player.types';

export default {
  namespaced: true,
  state: {
    track: undefined,
    trackHistory: [],
  },
  mutations,
  actions,
  getters,
};
