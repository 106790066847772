import {
  TRACKS_GET,
  TRACKS_GET_CITIES_SORTED,
  TRACKS_GET_COUNTRIES_SORTED,
  TRACKS_GET_FILTERED_PAGED,
  TRACKS_GET_GENRES_SORTED,
  TRACKS_GET_SORTED,
  TRACKS_GET_SORTED_FILTERED,
  TRACKS_GET_SORTED_FILTERED_PAGED,
  TRACKS_TOP_CHART_GET_ALL,
} from '@/store/tracks/tracks.enums';
import { genreSorter, stringSort, trackFilter, trackSorters } from '@/store/tracks/tracks.helpers';

export default {
  [TRACKS_TOP_CHART_GET_ALL]: (state) => (limit = 5) => Object
    .values(state.topChartTracks)
    .sort(trackSorters[state.sortBy])
    .slice(0, limit),
  [TRACKS_GET]: (state) => Object.values(state.tracks),
  [TRACKS_GET_SORTED]: (state) => Object
    .values(state.tracks)
    .sort(trackSorters[state.sortBy]),
  [TRACKS_GET_SORTED_FILTERED]: (state) => (filter = {}) => Object
    .values(state.tracks)
    .filter((track) => trackFilter(track, filter))
    .sort(trackSorters[state.sortBy]),
  [TRACKS_GET_SORTED_FILTERED_PAGED]: (state) => (filter = {}, limit = 20) => Object
    .values(state.tracks)
    .filter((track) => trackFilter(track, filter))
    .sort(trackSorters[state.sortBy])
    .slice(0, limit),
  [TRACKS_GET_FILTERED_PAGED]: (state) => (filter = {}, limit = 20) => Object
    .values(state.tracks)
    .filter((track) => trackFilter(track, filter))
    .slice(0, limit),
  [TRACKS_GET_GENRES_SORTED]: (state) => Object
    .entries(state.trackGenres)
    .sort(genreSorter)
    .reduce((acc, [name, count]) => [...acc,
      {
        name,
        count,
      },
    ], []),
  [TRACKS_GET_CITIES_SORTED]: (state) => Object
    .values(state.trackCities)
    .sort(stringSort),
  [TRACKS_GET_COUNTRIES_SORTED]: (state) => Object
    .values(state.trackCountries)
    .sort(stringSort),
};
