<template>
  <img
    :src="resolvedUri"
    class="track_artwork"
    alt="Track artwork"
    @error="onResolveError"
  >
</template>

<script>

  export default {
    name: 'TrackArtwork',
    props: {
      uri: {
        type: String,
        required: false,
        // eslint-disable-next-line global-require
        default: '', // require('../../public/artwork.gradient.svg'),
      },
    },
    data() {
      return {
        resolvedUri: this.uri,
      };
    },
    methods: {
      onResolveError() {
      // eslint-disable-next-line global-require
      // this.resolvedUri = require('../../public/artwork.gradient.svg');
      },
    },
  };

</script>

<style lang="scss">

.track_artwork {
  border-radius: 3px;
  width: auto;
  height: 30px;
  margin: 0px 10px;
}

</style>
