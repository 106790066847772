import Vue from 'vue';
import iView from 'iview';
import 'iview/dist/styles/iview.css';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';

import App from './App';
import store from './store';

if (process.env.NODE_ENV === 'production') {
  Raven
    .config('https://b8e60ce7fc3c4738bb151677ca44fe30@sentry.io/1275434')
    .addPlugin(RavenVue, Vue)
    .install();
}

Vue.use(iView);

Vue.config.productionTip = false;

const polyfill = [];

Promise
  .all(polyfill)
  .then(() => new Vue({
    store,
    render: (h) => h(App),
  }).$mount('#app'),
  );
