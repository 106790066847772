import { SORT_BY_LIKES } from '@/store/tracks/tracks.helpers';
import actions from './tracks.actions';
import mutations from './tracks.types';
import getters from './tracks.getters';

export default {
  namespaced: true,
  state: {
    tracks: {},
    topChartTracks: {},
    trackGenres: {},
    trackCities: {},
    trackCountries: {},
    searching: false,
    error: null,
    sortBy: SORT_BY_LIKES,
  },
  mutations,
  actions,
  getters,
};
