/* eslint-disable camelcase,global-require */
import md5 from 'md5';

const processTrackCollection = (trackCollection) => trackCollection.reduce((acc, track) => {
  const {
    tag_list: rawTagList,
    genre: rawGenre,
    artwork_url,
    comment_count = 0,
    created_at,
    download_count = 0,
    likes_count = 0,
    playback_count = 0,
    reposts_count,
    uri,
    title,
    permalink,
    duration,
    permalink_url,
    purchase_url,
    isDownloadable,
    downloadLink,
    country,
    city,
  } = track;

  let genre;

  switch ((rawGenre || '').toLowerCase()) {
    case 'dance e edm':
    case 'dance y edm':
    case 'dance en edm':
      genre = 'dance & edm';
      break;
    case 'mash up':
    case 'mash-up':
    case 'mashup':
      genre = 'mashup';
      break;
    case '':
      genre = '';
      break;
    default:
      genre = rawGenre.toLowerCase();
  }

  genre = genre.replace('\\', '');
  const tagList = (rawTagList || '').replace(/[\\"']/g, '');

  acc.tracks[md5(permalink || title)] = {
    id: md5(permalink || title),
    artwork_url: artwork_url || require('../../public/artwork.gradient.svg'),
    comment_count,
    created_at: new Date(created_at),
    download_count,
    likes_count,
    playback_count,
    reposts_count,
    tag_list: tagList,
    uri,
    genre,
    duration,
    permalink_url,
    purchase_url,
    isDownloadable,
    downloadLink,
    title: title || permalink,
    city,
    country,
  };

  if (acc.genres[genre]) {
    acc.genres[genre] += 1;
  } else {
    acc.genres[genre] = 1;
  }

  if (city && !acc.cities[city]) acc.cities[city] = city;
  if (country && !acc.countries[country]) acc.countries[country] = country;

  return acc;
}, {
  genres: {},
  tracks: {},
  cities: {},
  countries: {},
});

export default processTrackCollection;
