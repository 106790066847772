<template>
  <Layout :class="{app: true, 'app--with-player': !!playerTrack}">
    <Content :class="'main-content'">
      <Col
          class="app__info"
          type="flex"
          align="center"
      >
        <img
            src="/web-logo.svg"
            alt="Logo"
            class="app__logo"
        >
        <p>
          Searchsoundcloud.com is a simple tool which allows you to <b>quickly sort & filter soundcloud searches</b> to
          easily discover new and exciting music.
        </p>
        <br>
        <p>
          If you are experiencing unexpected results, <b>use the search filters</b> at first, <b>filtering by genre</b>
          should give you better results.
        </p>
        <br>
        <p>
          Is the site not working? Soundcloud probably rotated their api keys, let me know on <b><a
            href="https://www.reddit.com/r/DJs/comments/wkeoki/i_made_a_tool_for_musiciansdjs_to_easier_search/">reddit</a></b>
          and I'll fix it as soon as I can!
        </p>
      </Col>
      <Search />
    </Content>
    <div class="background" />
    <Player />
  </Layout>
</template>

<script>

import { TRACKS_FETCH_TOP_CHARTS } from '@/store/tracks/tracks.enums';
import { mapActions, mapState } from 'vuex';
import './helpers/soundcloudPlayer';
import Search from './components/Search';
import Player from './components/Player';

export default {
  name: 'App',
  components: {
    Search,
    Player,
  },
  computed: {
    ...mapState('Player', {
      playerTrack: 'track',
    }),
  },
  created() {
    this.getTopCharts();
  },
  methods: {
    ...mapActions('Tracks', {
      getTopCharts: TRACKS_FETCH_TOP_CHARTS,
    }),
  },
};
</script>

<style lang="scss">

body {
  overflow-x: hidden;
}

.app {
  min-height: 100vh;
  background: transparent;
  position: relative;

  &--with-player {
    padding-bottom: 40px;
  }

  &__info {
    margin-bottom: 20px;
  }

  &__logo {
    margin-bottom: 10px;
  }

}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  z-index: -1;
}

.main-content {
  margin: 20px 0;
  padding: 0 20px;
}
</style>
