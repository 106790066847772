import proxy from '@/config/proxy';
import processTopChartCollection from '@/helpers/processTopChartCollection';
import processTrackCollection from '@/helpers/processTrackCollection';
import {
  TRACKS_ADD_COLLECTION,
  TRACKS_SEARCH_SEARCHING,
  TRACKS_SEARCH,
  TRACKS_SEARCH_ERROR,
  TRACKS_FETCH_TOP_CHARTS,
  TRACKS_ADD_TOP_CHART,
  TRACKS_ADD_GENRES, TRACKS_ADD_COUNTRIES, TRACKS_ADD_CITIES,
} from '@/store/tracks/tracks.enums';

const actions = {
  [TRACKS_SEARCH]({ commit }, { searchTerm, searchFilters }) {
    const filterEntries = Object.entries(searchFilters);
    if (filterEntries.length === 0 && searchTerm.length <= 2) return Promise.resolve();

    commit(TRACKS_SEARCH_SEARCHING);
    return proxy
      .get('/tracks', {
        params: {
          q: searchTerm || '',
          ...filterEntries
            .reduce((acc, [filter, value]) => ({
              ...acc,
              [`filter.${filter}`]: value,
            }), {}),
        },
      })
      .then(({ data: { collection } }) => processTrackCollection(collection))
      .then(({ tracks, genres, cities, countries }) => {
        commit(TRACKS_ADD_COLLECTION, tracks);
        commit(TRACKS_ADD_GENRES, genres);
        commit(TRACKS_ADD_COUNTRIES, countries);
        commit(TRACKS_ADD_CITIES, cities);
      })
      .catch((error) => {
        commit(TRACKS_SEARCH_ERROR, error);
        // eslint-disable-next-line no-console
        return console.error(error);
      });
  },
  [TRACKS_FETCH_TOP_CHARTS]({ commit }) {
    return proxy
      .get('top-charts')
      .then(({ data: { collection } }) => processTopChartCollection(collection))
      .then((trackCollection) => commit(TRACKS_ADD_TOP_CHART, trackCollection));
  },
};

export default actions;
