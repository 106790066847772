// actions
export const TRACKS_SEARCH = 'TRACKS_SEARCH';
export const TRACKS_FETCH_TOP_CHARTS = 'TRACKS_FETCH_TOP_CHARTS';

// types
export const TRACKS_SEARCH_SEARCHING = 'TRACKS_SEARCH_SEARCHING';
export const TRACKS_ADD_COLLECTION = 'TRACKS_ADD_COLLECTION';
export const TRACKS_SEARCH_ERROR = 'TRACKS_SEARCH_ERROR';
export const TRACKS_SET_SORT_TYPE = 'TRACKS_SET_SORT_TYPE';
export const TRACKS_ADD_TOP_CHART = 'TRACKS_ADD_TOP_CHART';
export const TRACKS_ADD_GENRES = 'TRACKS_ADD_GENRES';
export const TRACKS_ADD_CITIES = 'TRACKS_ADD_CITIES';
export const TRACKS_ADD_COUNTRIES = 'TRACKS_ADD_COUNTRIES';

// getters
export const TRACKS_GET = 'TRACKS_GET_ALL';
export const TRACKS_TOP_CHART_GET_ALL = 'TRACKS_TOP_CHART_GET_ALL';
export const TRACKS_GET_SORTED = 'TRACKS_GET_SORTED';
export const TRACKS_GET_GENRES_SORTED = 'TRACKS_GET_GENRES_SORTED';
export const TRACKS_GET_SORTED_FILTERED = 'TRACKS_GET_SORTED_FILTERED';
export const TRACKS_GET_SORTED_FILTERED_PAGED = 'TRACKS_GET_SORTED_FILTERED_PAGED';
export const TRACKS_GET_FILTERED_PAGED = 'TRACKS_GET_FILTERED_PAGED';
export const TRACKS_GET_COUNTRIES_SORTED = 'TRACKS_GET_COUNTRIES_SORTED';
export const TRACKS_GET_CITIES_SORTED = 'TRACKS_GET_CITIES_SORTED';
