export const AFRO_HOUSE = 'Afro House';
export const BIG_ROOM = 'Big Room';
export const BREAKS = 'Breaks';
export const DANCE = 'Dance';
export const DEEP_HOUSE = 'Deep House';
export const DRUM_N_BASS = 'Drum & Bass';
export const DUBSTEP = 'Dubstep';
export const ELECTRO_HOUSE = 'Electro House';
export const ELECTRONICA = 'Electronica';
export const DOWNTEMPO = 'Downtempo';
export const FUNK = 'Funk';
export const SOUL = 'Soul';
export const DISCO = 'Disco';
export const FUNKY_HOUSE = 'Funky House';
export const FUNKY = 'Funky';
export const GROOVE_HOUSE = 'Groove';
export const JACKIN_HOUSE = 'Jackin\' House';
export const FUTURE_HOUSE = 'Future House';
export const GARAGE = 'Garage';
export const BASELINE = 'Bassline';
export const GRIME = 'Grime';
export const GLITCH_HOP = 'Glitch Hop';
export const HARD_DANCE = 'Hard Dance';
export const HARDCORE_TECHNO = 'Hardcore Techno';
export const HARD_TECHNO = 'Hard Techno';
export const HIP_HOP = 'Hip-Hop';
export const R_N_B = 'R&B';
export const HOUSE = 'House';
export const INDIE_DANCE = 'Indie Dance';
export const NU_DISCO = 'Nu Disco';
export const LEFTIFIELD_BASS = 'Leftfield Bass';
export const LEFTFIELD_HOUSE = 'Leftfield House';
export const LEFTFIELD_TECHNO = 'Leftfield Techno';
export const MELODIC_HOUSE = 'Melodic House';
export const MELODIC_TECHNO = 'Melodic Techno';
export const MINIMAL = 'Minimal';
export const PROGRESSIVE_HOUSE = 'Progressive House';
export const PSY_TRANCE = 'Psy-Trance';
export const REGGAE = 'Reggae';
export const DANCEHALL = 'Dancehall';
export const DUB = 'Dub';
export const TECH_HOUSE = 'Tech House';
export const TECHNO = 'Techno';
export const TRANCE = 'Trance';
export const TRAP = 'Trap';
export const FUTURE_BASS = 'Future Bass';
export const DANCE_N_EDM = 'Dance & Edm';
