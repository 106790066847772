<template>
  <div
    ref="track"
    :class="extraClasses"
    class="track"
  >
    <Card :bordered="false">
      <template slot="title">
        <Row
          :gutter="10"
          type="flex"
        >
          <Col>
            <a
              :href="track.permalink_url"
              target="_blank"
            >
              <Icon
                type="ios-link"
                size="18"
              />
            </a>
          </Col>
          <Col style="max-width: 90%; flex-grow: 1">
            <p>{{ track.title }}</p>
          </Col>

          <Col v-if="track.isDownloadable">
            <a
              :href="track.downloadLink"
              target="_blank"
            >
              <Icon
                type="ios-cart"
                size="18"
              />
            </a>
          </Col>

          <Col>
            <span
              v-if="track.genre || track.tag_list"
              :title="track.genre || track.tag_list"
              class="track__genre"
            >
              {{ track.genre || track.tag_list }}
            </span>
          </Col>

          <Col>
            {{ dateReleased }}
          </Col>
        </Row>
      </template>
      <div>
        <Row
          type="flex"
          align="middle"
        >
          <Col>
            <Icon
              :class="'track__play'"
              type="ios-play"
              @click="() => playTrack(track)"
            />
            <span
              v-if="track.duration"
              class="track__duration"
            > ({{ track.duration | msToTime }})</span>
          </Col>

          <Col :style="{display: 'flex'}">
            <TrackArtwork
              :uri="track.artwork_url"
            />
          </Col>

          <Col :class="'track__stats'">
            <Row
              type="flex"
              justify="space-between"
            >
              <TrackStat
                :stat-value="track.likes_count"
                :filtered-by="sortBy === SORT_BY_LIKES"
                icon="heart"
              />

              <TrackStat
                :stat-value="track.reposts_count"
                :filtered-by="sortBy === SORT_BY_REPOSTS"
                icon="repeat"
              />

              <TrackStat
                :stat-value="track.download_count"
                icon="cloud-download"
              />

              <TrackStat
                :stat-value="track.playback_count"
                :filtered-by="sortBy === SORT_BY_PLAYS"
                icon="play"
              />

              <TrackStat
                :stat-value="track.comment_count"
                :filtered-by="sortBy === SORT_BY_COMMENTS"
                icon="chatbubbles"
              />
            </Row>
          </Col>
        </Row>
      </div>
    </Card>
  </div>
</template>

<script>

  import { PLAYER_SET_PLAYING_TRACK, PLAYER_WAS_TRACK_PLAYED } from '@/store/player/player.enums';
  import { mapState, mapMutations, mapGetters } from 'vuex';

  import {
    SORT_BY_COMMENTS,
    SORT_BY_LIKES,
    SORT_BY_PLAYS,
    SORT_BY_REPOSTS,
  } from '@/store/tracks/tracks.helpers';

  import { format } from 'date-fns';
  import TrackStat from './TrackStat';
  import TrackArtwork from './TrackArtwork';

  export default {
    name: 'Track',
    components: {
      TrackStat,
      TrackArtwork,
    },
    filters: {
      formatStat(val = '') {
        return val.toString()
          .replace(/\d(?=(\d{3})+$)/g, '$&,');
      },
      msToTime(ms = 0) {
        let time = new Date(ms)
          .toISOString()
          .slice(11, -5);

        if (time.charAt(0) === '0' && time.charAt(1) === '0') time = time.slice(3);

        return time;
      },
    },
    props: {
      track: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapState('Tracks', ['sortBy']),
      ...mapState('Player', {
        currentlyPlaying: 'track',
      }),
      ...mapGetters('Player', {
        trackPlayed: PLAYER_WAS_TRACK_PLAYED,
      }),
      SORT_BY_REPOSTS: () => SORT_BY_REPOSTS,
      SORT_BY_LIKES: () => SORT_BY_LIKES,
      SORT_BY_PLAYS: () => SORT_BY_PLAYS,
      SORT_BY_COMMENTS: () => SORT_BY_COMMENTS,
      dateReleased() {
        return format(this.track.created_at, 'DD. MM. YYYY');
      },
      currentTrackPlayed() {
        return this.trackPlayed(this.track.id);
      },
      currentTrackPlaying() {
        return this.track.id === (this.currentlyPlaying && this.currentlyPlaying.id) || '';
      },
      extraClasses() {
        return {
          'track--played': this.currentTrackPlayed && !this.currentTrackPlaying,
          'track--playing': this.currentTrackPlaying,
        };
      },
    },
    methods: {
      ...mapMutations('Player', {
        playTrack: PLAYER_SET_PLAYING_TRACK,
      }),
    },
  };

</script>

<style lang="scss">
  .track {

    a {
      color: #c4c6c7;

      &:hover {
        color: #b2b4b5;
      }
    }

    &--played {
      opacity: 0.5;
      transition: opacity 0.3s linear;
      will-change: opacity;

      &:hover {
        opacity: 1
      }

    }

    &--playing {
      .ivu-card {
        background-image: linear-gradient(to top, #f77062 0%, #fe5196 100%);
        color: white;
      }

      .ivu-card-head {
        p, a {
          color: white;
        }
      }

      &__genre {
        background-color: white;
        color: black;
      }

    }

    &__duration {
      font-size: 10px;
      font-weight: bold;
      display: inline-block;
      vertical-align: text-bottom;
      margin-left: 5px;

      @media (max-width: 425px) {
        display: none;
      }

    }

    &__genre {
      font-size: 10px;
      font-weight: bold;
      background-image: linear-gradient(to top, #f77062 0%, #fe5196 100%);
      color: white;
      border-radius: 3px;
      padding: 2px 5px;
      vertical-align: bottom;
      display: inline-block;
      max-width: 11em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__stats {
      flex-grow: 1;
    }

    &__play {
      cursor: pointer;
    }

    &__artwork {
      border-radius: 3px;
      width: auto;
      height: 30px;
      margin-right: 10px;
      @media (max-width: 425px) {
        margin-right: 5px;
      }
    }

    & + & {
      margin-top: 10px;
    }
  }
</style>
